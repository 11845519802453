@use "sass:math";
@import '../../../../../../assets/mixins';
@import 'variables';

@mixin app-content {
    padding: 0 2rem !important;
    width: 100% !important;

    @include media(xs, sm) {
        padding: 0 0.5rem !important;
    }
    @include small-width() {
        padding: 0 1rem !important;
    }
}

@mixin border-radius($radius) {
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    -ms-border-radius: $radius;
    border-radius: $radius;
}

@mixin vcenter-absolute() {
    top: 50%;
    transform: translateY(-50%);
}

@mixin vbottom-absolute($offset: -50%) {
    top: 100%;
    transform: translateY($offset);
}

@mixin xy-centered-absolute() {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@mixin background-size($bgsize) {
    -webkit-background-size: $bgsize !important;
    -moz-background-size: $bgsize !important;
    -o-background-size: $bgsize !important;
    background-size: $bgsize !important;
}

@mixin wpm-gfb-gradient() {
    background: $main-grad-start !important;
    background: -moz-linear-gradient(left, $main-grad-start 40%, #00afff 100%) !important;
    background: -webkit-linear-gradient(left, $main-grad-start 40%, #00afff 100%) !important;
    background: linear-gradient(to right, $main-grad-start 40%, #00afff 100%) !important;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#006eff', endColorstr='#00afff', GradientType=1) !important;
}

@mixin wpm-gradient() {
    background: $main-grad-start !important;
    background: -moz-linear-gradient(left, $main-grad-start 40%, $main-grad-stop 100%) !important;
    background: -webkit-linear-gradient(left, $main-grad-start 40%, $main-grad-stop 100%) !important;
    background: linear-gradient(to right, $main-grad-start 40%, $main-grad-stop 100%) !important;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#006eff', endColorstr='#00afff', GradientType=1) !important;
}

@mixin wpm-gradient-reverse($startColor: $main-grad-start, $endColor: $main-grad-stop) {
    background: $endColor !important;
    background: -moz-linear-gradient(left, $endColor 0%, $startColor 100%) !important;
    background: -webkit-linear-gradient(left, $endColor 0%, $startColor 100%) !important;
    background: linear-gradient(to right, $endColor 0%, $startColor 100%) !important;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=$startColor, endColorstr=$endColor, GradientType=1) !important;
}

@mixin opacity($value) {
    $IEValue: $value * 100;
    opacity: $value;
    -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=' + $IEValue + ')';
    filter: alpha(opacity=$IEValue);
}

@mixin box-shadow($top, $left, $blur, $color, $inset: false) {
    @if $inset {
        -webkit-box-shadow: inset $top $left $blur $color;
        -moz-box-shadow: inset $top $left $blur $color;
        box-shadow: inset $top $left $blur $color;
    } @else {
        -webkit-box-shadow: $top $left $blur $color;
        -moz-box-shadow: $top $left $blur $color;
        box-shadow: $top $left $blur $color;
    }
}

@mixin drop-shadow {
    box-shadow: 1px 2px 5px 1px $black-alpha-11 !important;
}

@mixin light-drop-shadow {
    box-shadow: 0px 7px 6px -6px $black-alpha-20;
}

@mixin user-menu-drop-shadow {
    box-shadow: 0px 3px 10px 4px $black-alpha-20 !important;
}

@mixin app-box-shadow {
    -webkit-box-shadow: $box-shadow !important;
    -moz-box-shadow: $box-shadow !important;
    box-shadow: $box-shadow !important;
}

// REM => PX font size fall down
@mixin font-size($sizeValue: 1.6) {
    font-size: ($sizeValue * 16) + px;
    font-size: $sizeValue + rem;
}

// I like to move it, move it
@mixin keyframes($animation-name) {
    @-webkit-keyframes #{$animation-name} {
        @content;
    }
    @-moz-keyframes #{$animation-name} {
        @content;
    }
    @-ms-keyframes #{$animation-name} {
        @content;
    }
    @-o-keyframes #{$animation-name} {
        @content;
    }
    @keyframes #{$animation-name} {
        @content;
    }
}

@mixin animation($str) {
    -webkit-animation: #{$str};
    -moz-animation: #{$str};
    -ms-animation: #{$str};
    -o-animation: #{$str};
    animation: #{$str};
}

@mixin animation-delay($str) {
    -webkit-animation-delay: #{$str};
    -moz-animation-delay: #{$str};
    -ms-animation-delay: #{$str};
    -o-animation-delay: #{$str};
    animation-delay: #{$str};
}

@mixin transition($args...) {
    -webkit-transition: $args;
    -moz-transition: $args;
    -ms-transition: $args;
    -o-transition: $args;
    transition: $args;
}

// generic transform
@mixin transform($transforms) {
    -moz-transform: $transforms;
    -o-transform: $transforms;
    -ms-transform: $transforms;
    -webkit-transform: $transforms;
    transform: $transforms;
}

// rotate
@mixin rotate($deg) {
    @include transform(rotate(#{$deg}deg));
}

// scale
@mixin scale($scale) {
    @include transform(scale($scale));
}

// translate
@mixin translate($x, $y) {
    @include transform(translate($x, $y));
}

// skew
@mixin skew($x, $y) {
    @include transform(skew(#{$x}deg, #{$y}deg));
}

/* For browser dependent stuff */
@mixin browser($browsers: 'Mozilla') {
    @each $browser in $browsers {
        html[data-browser*='#{$browser}'] & {
            @content;
        }
    }
}

@mixin grid-child($col-start, $col-end, $row-start, $row-end) {
    -ms-grid-column: $col-start;
    -ms-grid-column-span: $col-end - $col-start;
    -ms-grid-row: $row-start;
    -ms-grid-row-span: $row-end - $row-start;
    grid-column: #{$col-start}/#{$col-end};
    grid-row: #{$row-start}/#{$row-end};
}

// Grid columns & rows with gaps for MS spec 1
@function box-gap($boxes, $gap) {
    $box: ();
    @for $i from 1 through length($boxes) {
        $box: append($box, nth($boxes, $i), space); // Adding Gap Between
        @if $gap > 0 {
            // Not last Loop
            @if $i != length($boxes) {
                $box: append($box, $gap, space);
            }
        }
    }
    @return $box;
}

@mixin wpm-button {
    border-radius: 0;
    @include font-size(0.875);
    text-align: center;
    min-width: 7.5rem;
    cursor: pointer;
    font-weight: 600;

    &[disabled] {
        cursor: not-allowed;
        filter: grayscale(100%);
    }
}

@mixin wpm-button-flat {
    @include wpm-button;
    @include wpm-gradient-reverse;
    padding: 0.3rem 2.5rem;
    border: none;
    color: white;
    transition: background-color 0.2s;

    // &:not([disabled]):hover {
    //     background: black !important;
    // }
}

@mixin wpm-button-stroked {
    @include wpm-button;
    color: #006eff;
    border: 2px solid #006eff;
    padding: calc(1rem - 1px) 1.5rem;
    background: none;
    transition: border-color 0.2s;
}

@mixin dpr1_25 {
    @media only screen and (-webkit-min-device-pixel-ratio: 1.25),
    only screen and (min--moz-device-pixel-ratio: 1.25),
    only screen and (-o-min-device-pixel-ratio: 5/4),
    only screen and (min-device-pixel-ratio: 1.25),
    only screen and (min-resolution: 1.25dppx),
    only screen and (min-resolution: 120dpi) {
        @content;
    }
}

@mixin dpr1_5 {
    @media only screen and (-webkit-min-device-pixel-ratio: 1.5),
    only screen and (min--moz-device-pixel-ratio: 1.5),
    only screen and (-o-min-device-pixel-ratio: 3/2),
    only screen and (min-device-pixel-ratio: 1.5),
    only screen and (min-resolution: 1.5dppx),
    only screen and (min-resolution: 144dpi) {
        @content;
    }
}

@mixin outline($width: 4px) {
    outline: $width solid rgba($main-color, 0.5) !important;
}

@mixin outline-inset($width: 4px) {
    @include outline($width);
    outline-offset: -$width;
}

// My client module mixins
// @FE devs, please use it for convert PX to REM
@function -em($pxValue) {
    @return math.div($pxValue, 16) + rem;
}
