@import "node_modules/@shared/ui-components/lib/styles/mixins";
@import "breakpoints-deprecated";

@mixin ie-10-11 {

    @media all and (-ms-high-contrast: none),
    (-ms-high-contrast: active) {
        @content;
    }
}

@mixin ms-edge {
    @supports (-ms-ime-align: auto) {
        // + 'and (-webkit-text-stroke: initial)' for newer ver
        @content;
    }
}
