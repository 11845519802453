// Basic values
$main-color: #0078fa;
$main-color-2: #0078fa;
$primary-blue: #0a68f1;

$base-color: $main-color-2;
$second-color: #eef0f5;

$main-grad-start: #006eff;
$main-grad-stop: #00d5ff;

$title-color: $base-color;
$label-background-color: $base-color;
$link-color: $base-color;

// Plain colors
$white: #fff;
$black: #000;
$red: #f00;

$grey-color: #959ba6;
$black-color: #212226;
$purple-color: #cd05ff;

$light-grey-text: #b9b8b8;

// design frame colors
$grey-border: #d2d2dc;
$box-shadow: 0px 5px 10px #0000001a;

// SIDE AND HEADER MENU DESIGN
$header-menu-height: 3.75rem;
$sidenav-small-width: 4.375rem; // 70px
$sidenav-large-width: 17.5rem; // 280px
$breadcrumbs-height: 8.2rem;
$breadcrumbs-height-small: 5rem;
$inner-nav-height: 3rem;
$loc-selector-height: 2rem;

// New Todo List variables.
$todo-left-padding: 1.7rem;
$todo-right-padding: 1.2rem;
$todo-group-content-max-height: 15rem;
$todo-item-height: 6rem;
$todo-list-done: #a8a8a8;
$todo-list-done-bg: #f5f5fa;

//CRM MODULE VARIABLES
$tooltip-icon-width: 40px;

// Common alphas
$transparent: transparent;
$black-alpha-04: rgba(0, 0, 0, 0.04);
$black-alpha-10: rgba(0, 0, 0, 0.1);
$black-alpha-11: rgba(0, 0, 0, 0.11);
$black-alpha-15: rgba(0, 0, 0, 0.15);
$black-alpha-20: rgba(0, 0, 0, 0.2);
$black-alpha-30: rgba(0, 0, 0, 0.3);
$black-alpha-54: rgba(0, 0, 0, 0.54);
$black-alpha-60: rgba(0, 0, 0, 0.6);
$black-alpha-75: rgba(0, 0, 0, 0.75);
$black-alpha-80: rgba(0, 0, 0, 0.8);
$black-alpha-87: rgba(0, 0, 0, 0.87);
$white-alpha-15: rgba(255, 255, 255, 0.15);
$white-alpha-20: rgba(255, 255, 255, 0.2);
$white-alpha-50: rgba(255, 255, 255, 0.5);
$white-alpha-75: rgba(255, 255, 255, 0.75);
$grey-alpha-30: rgba(155, 155, 155, 0.3);
$blue-alpha-10: rgba(32, 94, 217, 0.1);
$blue-alpha-25: rgba(66, 134, 244, 0.25);
$blue-alpha-26: rgba(80, 30, 255, 0.26);
$green-alpha-26: rgba(82, 215, 125, 0.26);
$tooltip-bg: rgba(255, 255, 255, 0.86);
$screen-shade: rgba(0, 0, 0, 0.7);
$del-btn-hover: rgba(255, 0, 0, 0.5);
$check-ripple: rgba(27, 142, 241, 0.15);

// Statuses
$status-pending: #9b9b9b;
$status-not_connected: #ebaa00;
$status-active: #56c224;
$status-rejected: #f00;
$status-error: #f00;
$error: #f00;
$success: #56c224;
$sending: #ebaa00;
$sent: #56c224;
$invalid: #f00;

$font-size-base: 14px;
$image-path: '/assets/images' !default;

$publishers-all: 'gmb' 'instagram' 'pagesjaunes' 'googlemaps' 'facebook' 'bing' 'apple' 'yahoo' 'foursquare' 'petitfute' '118000'
    '123pages' 'waze_navads' 'here' 'tomtom' 'navmii' 'seety' 'pharmanity' 'site_privilege' 'factual_navads' 'soms'
    'here_navads' 'garmin' 'bingplaces' 'navads' 'uber_navads' 'twitter' 'linkedin' 'pinterest' 'tripadvisor' 'ooreka'
    'qwant' 'apple_navads' 'justacote_navads' 'pages24_navads' 'horaire_navads' 'annuaire_navads' 'bing_navads'
    'trouverouvert_navads' 'horairesdouverture24_navads' 'linkedin2' 'twitter2';
$statuses: 'rejected' 'pending' 'not_connected' 'active' 'error' 'do_not_connect' 'deleted' 'duplicate';

// Login Page
$log-label: #828182;

// Post Page
$posts-list-bg: #fff;
$new-post-option-text: #0063d1;
$new-post-focused: #0063d1;
$new-post-picgrid-overlay: #9c9375;
$new-post-picgrid-overlay-edge: $black;
$new-post-action-icon: #0078fa;
$new-post-action-icon-hover: #0e2855;

$post-list-bg: #fff;
$post-list-item-loc-title: $black-alpha-80;
$post-list-item-pic-grid-overlay: $white;
$post-list-item-pub-locs-bg: #f2f4f6;
$post-list-item-pub-error: $error;
$post-list-item-pub-stat-num: #797979;

// Reviews Page
$rep-text: #959ba6;
$rev-dial-reply: $white;
$rev-dial-reply-bg: #0078fa;
$rev-dial-msg-border: #d8dbe1;

$rev-list-item-bg: $white;
$rev-list-item-border: $grey-border;
$rev-list-item-sep-top: $black-alpha-10;
$rev-list-item-not-read: #0078fa;
$rev-list-item-head: #202226;
$rev-list-item-icon: #2a5ed9;
$rev-list-item-subgrades: #f9fafc;
$rev-list-item-footer: #202226;
$rev-list-item-response: #2a5ed9;

$rev-list-bg: #fff;
$rev-list-info: #202226;
$rev-list-btn-bg: $white;
$rev-list-btn: #0078fa;

$rev-how-to: $white;
$rev-how-to-mob: #0078fa;

// Requests Redesign
$req-form-2-head-bg: $white;
$req-form-2-head-title: #18191a;
$req-form-2-location-border: #d0d3d9;
$req-form-2-location-border-field: #0078fa ;
$req-form-2-location-option-text:#18191a;
$req-form-2-location-shadow:#18191a1a;
$req-form-2-category-data: #212226;
$req-form-2-exp-title: #2b5eda;
$req-form-2-show-more-background: #0078fa1a;

// Locations Page
$loc-item-border: #d2d2dc;

// Location Detail Page
$loc-disp-media-add: #0078fa;
$loc-edit-title: #636467;
$loc-edit-sep: $black-alpha-10;
$loc-edit-checkbox: #959ba6;
$loc-edit-checked: #0078fa;
$loc-edit-text: #4c4c4c;
$loc-edit-help-text: #9b9b9b;
$loc-edit-section-bg: #fafafa;
$loc-edit-label: #828182;
$loc-edit-input-border: #d2d2dc;
$loc-edit-input-error: #ef5350;

// Statistics Page
$stats-insight-nodata: #828282;
$stats-tooltip: $black-color;

// Add CRM Page
$input-placeholder: #959ba6;
$crm-blue-2: #0078fa;

// Header
$head-user-menu-text: #6f6f6f;
$head-user-menu-hover: #0078fa;
$head-user-menu-logout: #0078fa;
$head-user-menu-border: $black-alpha-10;

// Menu
$menu-burger: $white;
$menu-sidebar-text: $white;
$menu-sidebar-bg: $base-color;

// Body
$body-bg: #fff;

// Filters
$mob-filter-header-txt: $white;
$mob-filter-header-bg: $base-color;

// Custom elements
$custom-select-bg: $base-color;
$custom-select-text: $white;
$datepicker-input-bg: $white;
$custom-checkbox-bg: $white;
$custom-checkbox-border: $base-color;
$custom-checkbox-checked: $base-color;
$custom-checkbox-tick: $base-color;
$custom-checkbox-tick-hover: #4a4a4a;
$custom-checkbox-label: #4a4a4a;
$custom-radio-circle: $base-color;
$custom-mat-list-bg: $white;
$custom-separator: #d8dbe1;
$custom-chip: $white;
$custom-chip-bg: $base-color;

// Pagination
$pagination-holder-border: #e7e7e7;

// Labels
$label-color: $white;
$label-warning: #d22424;
$label-active: #2a5ed9;
$label-hint: #b4b8bd;

// RDV Details
$rdv-text: #707070;
$rdv-caro-hold-bg: $white;

// Dialog
$dialog-header-bg: $base-color;
$dialog-text: #636467;
$dialog-border: #dcdcdc;
$dialog-icon: $base-color;
$dialog-cancel: #a5a5a5;
$dialog-confirm: $base-color;
$feedback-sending: $sending;
$feedback-sent: $sent;
$dialog-common-close: $white;
$dialog-common-close-ie: $white;

// Image Dialogs
$img-dialog-overlay-bg: $black-alpha-80;
$img-dialog-arrow-bg: $black-alpha-30;
$img-dialog-icon: $white;
$img-dialog-selected-thumb: $white;
$img-dialog-controllable-img-bg: $black;
$img-gallery-dialog-bg: $black-alpha-75;
$img-gallery-dialog-icon: $white;
$img-gallery-dialog-cp: $white;

// Inputs
$category-chip: #0078fa;
$input-add: $base-color;
$input-edit: $base-color;
$input-clear: $base-color;
$input-label: #959ba6;
$input-placeholder: #959ba6;
$input-warning: $label-warning;
$input-toggle-bg: $white;
$input-toggle-border: #808080;
$input-toggle-checked: #3a7fe6;
$input-option-text: #0063d1;
$input-option-selected-bg: #f1f1f2;
$input-add-slot: #3f68d4;
$input-mob-struct-blue: #0078fa;
$input-email-temp-bg: #f4f4f4;
$input-email-temp-thanks: #ffec00;

// Footer
$footer-bg: $white;
$footer-copy: #828182;

// Unsorted
$background: #1562bc;
$address-color: #626262;
$location-list-header: #4986cc;
$location-list-bg-color: $white;
$locations-title: #0075cf;
$location-selected: #f1f7ff;
$button: #00d9a3;
$button-hover: #09c696;
$headings: $white;
$placeholder: #abaaaa;
$percentage-label: #00d9a3;
$tabs-bg: #f0f0f0;
$tab-titles: #4a4a4a;
$tab-titles-shadow: $black;
$input-focus: #fffcd5;
$dropdown-active: #eee;
$remove-field-btn: $base-color;
$remove-field-btn-bg: $white;

$image-upload-bg: $base-color;
$image-upload-bg-hover: #4986cc;
$image-item-hover: $base-color;
$publisher-disconnect-link: #f00;
$publisher-view-link: #56c224;

$fb-tutorial-indicators: #acacac;
$fb-tutorial-active-indicator: $base-color;
$fb-tutorial-nav: $base-color;
$fb-tutorial-description: #4a4a4a;
$modal-header-bg: #4986cc;
$disabled-action: #acacac;
$tutorial-link-color: #0078fa;

// Warning banner
$warning-banner-bg: #ffb527;

// Dialog
$dialog-title-color: $black;
$dialog-text-color: #707070;
$dialog-colored-header-bg: #e5e5e580;
$dialog-step-line-color:#959BA6;

// Quotation
$quotation-unread-color: $main-color;
$quotation-settings-activated-color: #19d77d;
$quotation-settings-deactivated-color: #979ca8;

// Dialog
$dialog-title-color: $black;
$dialog-text-color: #707070;
$dialog-colored-header-bg: #e5e5e580;

// Form
$slm-input-bg: #fafafa;
$slm-input-color: #4c4c4c;
$slm-label-color: #828182;

// My Client Page colors
// ! Deprecated color names, do not use
// ! Use the colors bellow
$my-client-button-disable-bg: #fafafb;
$my-client-button-cancel-bg-hover: #f1f1f2;
$my-client-button-cancel-bg-activate: #C4C5C7;
$my-client-box-shadow-color: #00000029;
$my-client-detail-box-shadow-color: #0000001a;
$my-client-detail-blue-color: #0a68f1;
$my-client-progress-buffer-bg: #cde9fe;
$my-client-grey: #d2d2dc;
// My clients module colors

$my-client-grey: #d2d2dc;
$my-client-grey-dark: #6e7075;
$my-client-grey-darker: #73767c;
$my-client-black: #000;
$my-client-blue: #0a68f1;
$my-client-spring-green: #12ee86;

$my-client-green: #008a00;

$my-client-red: #e32424;

//Activation feature
$pending-color: #ffaf14;

$solocal-blue: #0078fa;

$input-select-grey-bg: #ebebeb;

// Carousel
$carousel-arrow-color: #797a7c;

// font weights
$font-medium: 500;
